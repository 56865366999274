import {Helmet, useI18next} from 'gatsby-plugin-react-i18next';
import favicon from 'images/favicon.png';
import React from 'react';
import default_preview from 'images/preview.png';

type Props = {
  title?: string;
  description?: string;
  preview?: string;
  bodyAttributes?: Record<string, any>;
};

export const SEO: React.FC<Props> = (props) => {
  const {t, language} = useI18next();
  const title = props.title || t('microapps - making your ecommerce journey easier');
  const desc =
    props.description ||
    t(
      'microapps is a technology company with a worldwide distributed team across more than 5 countries. We help companies sell more.'
    );
  const preview = props.preview || default_preview;
  const siteTitle = 'microapps';
  return (
    <Helmet defer={false} titleTemplate={`%s | ${siteTitle}`} bodyAttributes={props.bodyAttributes}>
      <meta charSet="utf-8" />
      <title>{title}</title>
      <meta name="description" content={desc} />
      <link rel="shortcut icon" href={favicon} />

      {/* Facebook */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={desc} />
      <meta property="og:image" content={preview} />
      <meta property="og:type" content="website" />
      <meta property="og:site_name" content={siteTitle} />
      <meta property="og:locale" content={language} />
      <meta property="og:image:alt" content={desc} />

      {/* Twitter */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image" content={preview} />
      <meta name="twitter:description" content={desc} />
      <meta name="twitter:image:alt" content={desc} />
    </Helmet>
  );
};
