import React from 'react';
import {Link as RouterLink} from 'gatsby-plugin-react-i18next';

type Props = React.AnchorHTMLAttributes<HTMLAnchorElement> & {to?: string};

export const Link: React.FC<Props> = (props) => {
  const href = props.href || props.to;
  if (href?.startsWith('/')) {
    return <RouterLink {...props} to={href!} />;
  }
  return (
    <a
      {...(props.target === '_blank' ? {rel: 'noopener noreferrer'} : {})}
      {...props}
      href={href}
    />
  );
};
