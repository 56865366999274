import React, {useState} from 'react';
import logo from 'images/microapps.svg';
import {Trans} from 'gatsby-plugin-react-i18next';
import {Transition} from '@tailwindui/react';
import useOnclickOutside from 'react-cool-onclickoutside';
import {Link} from 'components/Link';
import {HiMenu, HiX} from 'react-icons/all';

export const Header: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });
  return (
    <div className="absolute w-full z-50">
      <div className="container px-6 py-7 md:py-14 relative z-10">
        <div className="flex items-center justify-between md:justify-start" ref={ref}>
          <Link to="/" className="mr-20">
            <img src={logo} alt="microapps" className="w-52" />
          </Link>
          <nav className="hidden md:flex space-x-20">
            <Link
              to="/careers/"
              className="uppercase text-base font-medium text-gray-600 hover:text-gray-900 tracking-wide">
              <Trans>We are hiring</Trans>
            </Link>
            <a
              href="mailto:hi@microapps.com"
              className="uppercase text-base font-medium text-gray-600 hover:text-gray-900 tracking-wide">
              <Trans>Contact us</Trans>
            </a>
          </nav>
          <div className="-mr-2 md:hidden">
            <button
              type="button"
              onClick={() => setIsOpen(true)}
              className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500">
              <span className="sr-only">Open menu</span>
              <HiMenu className="h-6 w-6" />
            </button>
            <Transition
              show={isOpen}
              className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1">
              <div className="rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 overflow-hidden">
                <div className="relative grid bg-white p-5 sm:gap-8 sm:p-8">
                  <nav className="grid gap-y-8">
                    <div className="flex justify-between">
                      <Link to="/" onClick={() => setIsOpen(false)}>
                        <img src={logo} alt="microapps" className="w-52" />
                      </Link>
                      <button
                        type="button"
                        onClick={() => setIsOpen(false)}
                        className="-mr-2 self-center bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-600 hover:text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                        <span className="sr-only">Close menu</span>
                        <HiX className="h-6 w-6" />
                      </button>
                    </div>
                    <Link
                      to="/careers"
                      onClick={() => setIsOpen(false)}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 text-base font-medium text-gray-900 hover:text-gray-700">
                      <Trans>We are hiring</Trans>
                    </Link>
                    <a
                      href="mailto:hi@microapps.com"
                      onClick={() => setIsOpen(false)}
                      className="-m-3 p-3 flex items-center rounded-md hover:bg-gray-50 text-base font-medium text-gray-900 hover:text-gray-700">
                      <Trans>Contact us</Trans>
                    </a>
                  </nav>
                </div>
              </div>
            </Transition>
          </div>
        </div>
      </div>
    </div>
  );
};
