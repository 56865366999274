import {TFunction} from 'i18next';
import amazon_logo from 'images/amazon_logo.svg';
import wholesaler_logo from 'images/wholesaler_logo.png';

export const getProducts = (t: TFunction) => [
  {
    name: 'MoonMail',
    description: t(
      'The all in one Messaging Platform: Email, SMS, Voice, WhatsApp, FB Messenger, Amazon (ADM), Apple (APNs), Baidu & Firebase (FCM). More than 5,000 companies rely on MoonMail for their OmniChannel customer communication. '
    ),
    logo: 'https://static.moonmail.io/moonmail-logo-white.svg',
    color: '#162A49',
    url: 'https://moonmail.io/'
  },
  {
    name: 'Wholesaler for Shopify',
    description: t(
      'Using Wholesaler for Shopify you can easily choose which customers should have access to wholesale prices. The approved wholesale customers are the only ones who can see wholesale product prices when they’re logged in all within one single Shopify store.'
    ),
    logo: wholesaler_logo,
    color: '#F44236',
    url: 'https://apps.shopify.com/wholesaler'
  },
  {
    name: 'Ecommerce Marketplaces',
    description: t(
      'microapps management of all operations for pet, furniture, pharma, kitchen / garden & games verticals within multiple marketplaces like Amazon Vendor Central, Cdiscount, Carrefour, El Corte Inglés, Alibaba, Zalando, Glovo, PcComponentes, ManoMano, MediaMarkt... Processing more than 3 M € gross sales per month.'
    ),
    logo: amazon_logo,
    color: '#F3F6F3',
    url: '/ecommerce-marketplaces'
  }
];
