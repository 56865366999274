import {Link, useI18next} from 'gatsby-plugin-react-i18next';
import React, {useState} from 'react';
import {Transition} from '@tailwindui/react';
import ISO6391 from 'iso-639-1';
import useOnclickOutside from 'react-cool-onclickoutside';
import {HiChevronDown} from 'react-icons/all';

type Props = {};

export const LanguageSelector: React.FC<Props> = () => {
  const [isOpen, setIsOpen] = useState(false);
  const {language, languages, originalPath} = useI18next();
  const ref = useOnclickOutside(() => {
    setIsOpen(false);
  });

  return (
    <div className="relative inline-block text-left" ref={ref}>
      <Transition
        show={isOpen}
        className="origin-bottom-left absolute left-0 bottom-full mb-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5"
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95">
        <div
          className="py-1"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu">
          {languages.map((code) => (
            <Link
              key={code}
              to={originalPath}
              language={code}
              onClick={() => setIsOpen(false)}
              className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-gray-900">
              {ISO6391.getNativeName(code)}
            </Link>
          ))}
        </div>
      </Transition>
      <button
        type="button"
        onClick={() => setIsOpen((open) => !open)}
        className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 font-medium focus:outline-none focus:ring-2"
        id="options-menu"
        aria-haspopup="true"
        aria-expanded="true">
        {ISO6391.getNativeName(language)}
        <HiChevronDown className="-mr-1 ml-2 h-5 w-5" />
      </button>
    </div>
  );
};
