import {LanguageSelector} from 'components/LanguageSelector';
import {Link} from 'components/Link';
import {getProducts} from 'data/products';
import {Trans, useI18next} from 'gatsby-plugin-react-i18next';
import aws_partner_logo from 'images/aws_partner_logo.png';
import shopify_plus_experts from 'images/shopify_plus_experts.svg';
import React from 'react';
import {HiOutlineLocationMarker, HiOutlineMail} from 'react-icons/all';
import {RiFacebookFill, RiInstagramFill, RiLinkedinFill, RiTwitterFill} from 'react-icons/ri';

export const Footer: React.FC = () => {
  const {t} = useI18next();
  const products = getProducts(t);
  return (
    <footer>
      <div className="bg-gray-800 text-gray-100">
        <div className="container px-6 py-14 relative z-10 flex justify-between md:flex-row flex-col space-y-14 md:space-y-0">
          <div>
            <LanguageSelector />
          </div>
          <div>
            <h3 className="font-bold text-lg text-white mb-3">
              <Trans>Careers</Trans>
            </h3>
            <nav className="list-none space-y-3 mb-5">
              <li>
                <Link to="/internships/" className="hover:underline">
                  <Trans>Internships</Trans>
                </Link>
              </li>
              <li>
                <Link to="/careers/" className="hover:underline">
                  <Trans>We’re hiring!</Trans>
                </Link>
              </li>
            </nav>
            <h3 className="font-bold text-lg text-white mb-3">
              <Trans>Legal</Trans>
            </h3>
            <nav className="list-none space-y-3">
              <li>
                <Link to="/privacy-policy/" className="hover:underline">
                  <Trans>Privacy Policy</Trans>
                </Link>
              </li>
              <li>
                <Link to="/terms-of-service/" className="hover:underline">
                  <Trans>Terms of Service</Trans>
                </Link>
              </li>
            </nav>
          </div>
          <div>
            <h3 className="font-bold text-lg text-white mb-3">
              <Trans>Products</Trans>
            </h3>
            <nav className="list-none space-y-3">
              {products.map((product, i) => (
                <li key={i}>
                  <Link
                    to={product.url}
                    target="_blank"
                    className="hover:underline"
                    rel="noopener noreferrer">
                    {product.name}
                  </Link>
                </li>
              ))}
            </nav>
          </div>
          <div>
            <h3 className="font-bold text-lg text-white mb-3">
              <Trans>Contact</Trans>
            </h3>
            <nav className="list-none space-y-3">
              <li className="flex items-center">
                <HiOutlineLocationMarker className="w-4 h-4 mr-2 mt-1" />
                <address>
                  Palestina, 1
                  <br />
                  29004 - Málaga, Spain
                </address>
              </li>
              <li className="flex items-center">
                <HiOutlineLocationMarker className="w-4 h-4 mr-2 mt-1" />
                <address>
                  Pujades, 100
                  <br />
                  08005 - Barcelona, Catalunya
                </address>
              </li>

              <li className="flex items-center">
                <HiOutlineLocationMarker className="w-4 h-4 mr-2 mt-1" />
                <address>
                  154 Grand St. New York
                  <br />
                  New York, 10013 USA
                </address>
              </li>
              <li className="flex items-center">
                <HiOutlineMail className="w-4 h-4 mr-2" />
                <a href="mailto:hi@microapps.com" className="hover:underline">
                  hi@microapps.com
                </a>
              </li>
            </nav>
          </div>
          <div className="space-y-10">
            <div>
              <img src={shopify_plus_experts} alt="Shopify Plus experts" width={200} />
            </div>
            <div>
              <img src={aws_partner_logo} alt="Amazon Web Services Advanced Technology Partner" />
            </div>
          </div>
        </div>
      </div>
      <div className="bg-gray-900 text-gray-400">
        <div className="container px-6 py-5 relative z-10 flex justify-between md:flex-row flex-col flex-col-reverse">
          <div>Copyright © {new Date().getFullYear()} microapps. All rights reserved.</div>
          <div className="flex space-x-10 md:space-x-6 items-center mb-6 md:mb-0 text-lg">
            <a
              href="https://www.facebook.com/microappshq"
              className="hover:text-white transition-colors"
              target="_blank"
              rel="noopener noreferrer">
              <RiFacebookFill />
            </a>
            <a
              href="https://www.linkedin.com/company/mircoapps/"
              className="hover:text-white transition-colors"
              target="_blank"
              rel="noopener noreferrer">
              <RiLinkedinFill />
            </a>
            <a
              href="https://twitter.com/microapps"
              className="hover:text-white transition-colors"
              target="_blank"
              rel="noopener noreferrer">
              <RiTwitterFill />
            </a>
            <a
              href="https://www.instagram.com/microappshq/"
              className="hover:text-white transition-colors"
              target="_blank"
              rel="noopener noreferrer">
              <RiInstagramFill />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};
