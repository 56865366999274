import 'styles/global.css';
import {SEO} from 'components/SEO';
import {Footer} from 'layouts/footer';
import {Header} from 'layouts/header';
import React from 'react';

type LayoutProps = {
  path: string;
};

const Layout: React.FC<LayoutProps> = ({children, path}) => {
  return (
    <div>
      <SEO />
      <Header />
      {children}
      <Footer />
    </div>
  );
};

export default Layout;
